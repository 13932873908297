import { BrPage, BrComponent } from "@bloomreach/react-sdk";
import { initialize } from "@bloomreach/spa-sdk";
import axios from "axios";
import { useEffect, useState, useRef } from "react";
import { ThirdPartyScripts } from "@/components/ThirdPartyScripts";
import { GetStaticProps, GetStaticPaths, InferGetStaticPropsType } from "next";
import { AppInsightsContext } from "@microsoft/applicationinsights-react-js";
import { reactPlugin } from "services/AppInsights";
import styles from "@/styles/Index.module.scss";
import clsx from "clsx";
import { ParallaxProvider } from "react-scroll-parallax";
import {
  PathContext,
  PreviewContext,
  MetaFlagsContext,
} from "state_management/Contexts";
import { Index as Footer } from "@/components/Footer";
import { Index as AgentBar } from "@/components/AgentBar";
import { Index as CookiesWrapper } from "@/components/CookiesWrapper";
import { Index as Meta } from "@/components/Meta";
import { Index as BreadcrumbsList } from "@/components/BreadcrumbsList";
import { AdobeAnalytics } from "@/components/AdobeAnalytics";
import { mapping } from "@/components/brMapping";

export const getStaticPaths: GetStaticPaths = async () => {
  return {
    paths: [{ params: { slug: [] } }],
    fallback: "blocking",
  };
};

export const getStaticProps: GetStaticProps = async (context) => {
  const isProd = process.env.NODE_ENV === "production";
  type Configuration = {
    path?: string;
    endpoint?: string;
  };

  const configuration: Configuration = {
    endpoint: process.env.NEXT_PUBLIC_BR_CONTENT_ENDPOINT,
  };

  if (!context.preview && isProd) {
    const slug = context.params?.slug?.toString().replaceAll(",", "/");
    let path = "/";

    if (slug) path = path + slug;

    configuration.path = path;
  }

  const page = await initialize({ ...configuration, httpClient: axios });

  let productApiData;

  // Product API call
  const apiUrl = process.env.NEXT_PUBLIC_PRODUCT_API_URL;
  const endpoint = apiUrl
    ? `${apiUrl}/api/v4/category/GetCategory/?datasource=STEP`
    : "";

  const productApiRes = await fetch(endpoint);

  productApiData = await productApiRes.json();

  return {
    props: {
      configuration,
      page: page.toJSON(),
      isProd: isProd,
      context: context,
      productData: productApiData,
    },
  };
};

export default function Index({
  configuration,
  page,
  context,
  productData,
}: InferGetStaticPropsType<typeof getStaticProps>) {
  const [path, setPath] = useState<PathProps>({ path: "", search: "" });
  const [flags, setFlags] = useState<string[] | null>(null);
  const brConfig = useRef(configuration);

  useEffect(() => {
    // Required for Experience Manager to work properly
    // Checks if inside an iframe, means we are inside Experience Manager
    // Checks if NextJS preview mode is enabled

    if (window.self !== window.top || context.preview) {
      const splusBasePath = "/services/printing";

      let pathname = window.location.pathname.replace(splusBasePath, "");

      if (!pathname) pathname = "/";

      configuration.path = `${pathname}${window.location.search}`;
      brConfig.current = { ...configuration, httpClient: axios };
    }

    setPath({ path: window.location.pathname, search: window.location.search });
  }, [configuration, context.preview]);

  return (
    <AppInsightsContext.Provider value={reactPlugin}>
      <CookiesWrapper>
        <ParallaxProvider>
          <BrPage
            configuration={brConfig.current}
            mapping={mapping}
            page={page}
          >
            <ThirdPartyScripts pagePath={path} />
            <MetaFlagsContext.Provider value={{ flags, setFlags }}>
              <AdobeAnalytics pagePath={path} productData={productData} />
              <PathContext.Provider value={path}>
                <PreviewContext.Provider value={context?.preview}>
                  <Meta />
                  {context?.preview && <BreadcrumbsList context={context} />}
                  <div className={styles.wrapperNew}>
                    <div className={styles.headerV2}>
                      <AgentBar />
                      <BrComponent path="navbar">
                        <BrComponent />
                      </BrComponent>
                    </div>

                    <div>
                      <BrComponent path="ribbon">
                        <BrComponent />
                      </BrComponent>
                    </div>
                    <div
                      className={clsx(styles.main, {
                        [styles.mainNew]: flags && flags.includes("new"),
                      })}
                    >
                      <BrComponent path="main">
                        <BrComponent />
                      </BrComponent>
                    </div>
                    <div className={styles.footer}>
                      <BrComponent path="footer">
                        <Footer />
                      </BrComponent>
                    </div>
                  </div>
                </PreviewContext.Provider>
              </PathContext.Provider>
            </MetaFlagsContext.Provider>
          </BrPage>
        </ParallaxProvider>
      </CookiesWrapper>
    </AppInsightsContext.Provider>
  );
}
