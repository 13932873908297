import { TypeFromSet } from "services/typeHelpers";
import Cookies from "universal-cookie";

const ptoTemplatesPages = new Set([
  "yard-lawn-signs",
  "custom-banners",
] as const);

const ptoTemplatesPagesPostersTest = new Set([
  "posters",
  "adhesive-posters",
  "foam-boards",
] as const);

const ptoTemplatesPagesBcTest = new Set([
  "traditional-business-cards",
  "specialty-business-cards",
] as const);

type PtoTemplatesPageType = TypeFromSet<typeof ptoTemplatesPages>;
type PtoTemplatesPostersTestType = TypeFromSet<
  typeof ptoTemplatesPagesPostersTest
>;
type PtoTemplatesBcTestType = TypeFromSet<typeof ptoTemplatesPagesBcTest>;

export function setBrowseDesignsPath(productType: string) {
  const cookies = new Cookies();
  const postersSearchFlow = cookies.get("ptemp");
  const businessCardsSearchFlow = cookies.get("bctemp");

  let browsePtoTemplates = false;

  switch (true) {
    case ptoTemplatesPages.has(productType as PtoTemplatesPageType):
      browsePtoTemplates = true;
      break;

    case postersSearchFlow === "ptemp1" &&
      ptoTemplatesPagesPostersTest.has(
        productType as PtoTemplatesPostersTestType
      ):
      browsePtoTemplates = true;
      break;

    case businessCardsSearchFlow === "bctemp1" &&
      ptoTemplatesPagesBcTest.has(productType as PtoTemplatesBcTestType):
      browsePtoTemplates = true;
      break;

    default:
      browsePtoTemplates = false;
      break;
  }

  if (browsePtoTemplates) return `${productType}/templates/`;
  return productType;
}
