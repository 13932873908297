import styles from "../Breadcrumbs/Breadcrumbs.module.scss";
import { v4 as uuidv4 } from "uuid";
import clsx from "clsx";

export const Breadcrumbs = ({
  breadcrumbs,
  search,
  isBanner,
}: BreadcrumbsProps) => {
  const isProd = process.env.NODE_ENV === "production";

  return (
    <ul
      className={clsx(styles.bcContainer, {
        [styles.bcContainerHide]:
          (isBanner && breadcrumbs.length > 1) || !isBanner,
      })}
    >
      <li
        className={clsx(styles.first, {
          [styles.firstBanner]: isBanner,
          [styles.firstHide]: isBanner && breadcrumbs.length > 1,
        })}
        data-testid="home_bc"
      >
        <a
          href={
            isProd
              ? `${process.env.NEXT_PUBLIC_BASEPATH}`
              : `${process.env.NEXT_PUBLIC_BASEPATH}${search}`
          }
        >
          Print & Marketing Services
        </a>
      </li>
      {breadcrumbs.map((bc) => {
        return (
          <li
            key={uuidv4()}
            className={clsx(styles.item, {
              [styles.itemBanner]: isBanner,
              [styles.itemHide]: isBanner && breadcrumbs.length > 1,
            })}
            data-testid="added_bc"
          >
            <a href={bc?.flag === "int" ? bc.key + search : bc?.key}>
              {bc.value}{" "}
            </a>
          </li>
        );
      })}
    </ul>
  );
};
