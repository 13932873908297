import { forwardRef, ForwardedRef, useState, useEffect } from "react";
import styles from "./UtilityMenuDropdown.module.scss";
import { useBoundingClientRect } from "hooks/useBoundingClientRect";
import { useFocusTrap } from "hooks/useFocusTrap";
import Link from "next/link";
import { MENU_DATA } from "../utilityMenuHelpers/links";
import clsx from "clsx";
import { UtilityDropdownProps } from "../utilityMenuHelpers/types";
import { utilityMenuTagManager } from "../utilityMenuHelpers/tagManager";
import { getReturnUrl } from "../utilityMenuHelpers/getReturnUrl";
import { UtilityMenuGreeting } from "../UtilityMenuGreeting";
import { UtilityMenuVerticalList } from "../UtilityMenuVerticalList";
import listStyles from "../UtilityMenuVerticalList/UtilityMenuVerticalList.module.scss";

export const UtilityMenuDropdown = forwardRef(function AccountDropdown(
  props: UtilityDropdownProps,
  ref: ForwardedRef<HTMLDialogElement>
) {
  const [domElement, setDomElement] = useState<HTMLElement | null>(null);
  const [signInWithReturnUrl, setSignInWithReturnUrl] = useState("");
  const [registerWithReturnUrl, setRegisterWithReturnUrl] = useState("");

  const returnUrlAccount = getReturnUrl(MENU_DATA.account.link);
  const returnUrlProjects = getReturnUrl(MENU_DATA.projects.link);
  const returnUrlFiles = getReturnUrl(MENU_DATA.files.link);
  const signInWithAccountReturnUrl = `${MENU_DATA.signIn.link}${returnUrlAccount}`;
  const signInWithProjectsReturnUrl = `${MENU_DATA.signIn.link}${returnUrlProjects}`;
  const signInWithFilesReturnUrl = `${MENU_DATA.signIn.link}${returnUrlFiles}`;

  const boundingRect = useBoundingClientRect(domElement);
  const handleFocus = useFocusTrap(ref as React.RefObject<HTMLDialogElement>);

  // Calculates nav's dimensions and position on page
  useEffect(() => {
    const element = document.getElementById("global-cms-header");
    if (element instanceof HTMLElement) {
      setDomElement(element);
    }
  }, []);

  // Sets states for Sign In URL and Registration URL
  useEffect(() => {
    const returnUrl = getReturnUrl();
    setSignInWithReturnUrl(`${MENU_DATA.signIn.link}${returnUrl}`);
    setRegisterWithReturnUrl(`${MENU_DATA.register.link}${returnUrl}`);
  }, []);

  // Positions the backdrop when dropdown menus are open
  const backdropOffset = boundingRect
    ? {
        top: `calc(${boundingRect.top}px + ${boundingRect.height}px)`,
      }
    : {};

  function closeDialog() {
    (ref as React.RefObject<HTMLDialogElement>).current?.close();
  }

  function handleKeyDown(event: React.KeyboardEvent<HTMLElement>) {
    if (event.key === "Escape") {
      closeDialog();
      return;
    }
    handleFocus(event);
  }

  function handleClick(gaLabel: string) {
    utilityMenuTagManager(gaLabel);
    closeDialog();
  }

  const AccountIcon = MENU_DATA.account.icon;
  const FoldersIcon = MENU_DATA.projects.icon;
  const FilesIcon = MENU_DATA.files.icon;
  const SignOutIcon = MENU_DATA.signOut.icon;

  return (
    <dialog
      ref={ref}
      className={clsx(styles.dialog, {
        [styles.dialogHelp]: props.menu === "help",
        [styles.dialogSignedIn]: props.isSignedIn,
      })}
      onKeyDown={handleKeyDown}
    >
      {props.menu === "account" && (
        <div className={styles.account}>
          <div className={styles.accountHeader}>
            {!props.isSignedIn && (
              <>
                <a
                  href={signInWithReturnUrl}
                  onClick={() => handleClick(MENU_DATA.signIn.label)}
                  data-pniautomationid="HeaderMenuDropdown"
                  className={styles.accountSignIn}
                >
                  {MENU_DATA.signIn.label}
                </a>
                <span className={styles.accountRegisterLabel}>
                  New Customer?
                </span>
                <a
                  href={registerWithReturnUrl}
                  onClick={() => handleClick(MENU_DATA.register.label)}
                  data-pniautomationid="HeaderNewCustomerBtn"
                  className={styles.accountRegister}
                >
                  {MENU_DATA.register.label}
                </a>
              </>
            )}
            {props.isSignedIn && <UtilityMenuGreeting />}
          </div>
          <UtilityMenuVerticalList>
            <li>
              <a
                href={
                  props.isSignedIn
                    ? MENU_DATA.account.link
                    : signInWithAccountReturnUrl
                }
                onClick={() => handleClick(MENU_DATA.account.label)}
                data-pniautomationid="HeaderAccountIcon"
                className={listStyles.utilityMenuVerticalListItem}
              >
                <AccountIcon />
                {MENU_DATA.account.label}
              </a>
            </li>
            <li>
              <a
                href={
                  props.isSignedIn
                    ? MENU_DATA.projects.link
                    : signInWithProjectsReturnUrl
                }
                onClick={() => handleClick(MENU_DATA.projects.label)}
                data-pniautomationid="HeaderFoldersIcon"
                className={listStyles.utilityMenuVerticalListItem}
              >
                <FoldersIcon />
                {MENU_DATA.projects.label}
              </a>
            </li>

            {/*My Files Link placeholder. Ready to go. Place to the right position of the list and change icon. */}

            {/* <li>
              <a
                href={
                  props.isSignedIn
                    ? MENU_DATA.files.link
                    : signInWithFilesReturnUrl
                }
                onClick={() => handleClick(MENU_DATA.files.label)}
                className={listStyles.utilityMenuVerticalListItem}
              >
                <FilesIcon />
                {MENU_DATA.files.label}
              </a>
            </li> */}

            {props.isSignedIn && (
              <li>
                <a
                  href={MENU_DATA.signOut.link}
                  className={listStyles.utilityMenuVerticalListItem}
                  onClick={() => handleClick(MENU_DATA.signOut.label)}
                  data-pniautomationid="HeaderSignOutIcon"
                >
                  <SignOutIcon />
                  {MENU_DATA.signOut.label}
                </a>
              </li>
            )}
          </UtilityMenuVerticalList>
        </div>
      )}

      {props.menu === "help" && (
        <UtilityMenuVerticalList>
          <li>
            <a
              href={MENU_DATA.helpCenter.link}
              className={listStyles.utilityMenuVerticalListItem}
              onClick={() => handleClick(MENU_DATA.helpCenter.label)}
            >
              {MENU_DATA.helpCenter.label}
            </a>
          </li>
          <li>
            <a
              href={`tel:+${MENU_DATA.customerService.link}`}
              className={clsx(
                listStyles.utilityMenuVerticalListItem,
                listStyles.utilityMenuVerticalListItemBold
              )}
              onClick={() => handleClick(MENU_DATA.customerService.label)}
            >
              {`${MENU_DATA.customerService.labelShort}: ${MENU_DATA.customerService.link}`}
            </a>
          </li>
        </UtilityMenuVerticalList>
      )}
      <div
        className={styles.dialogBackdrop}
        style={backdropOffset}
        onClick={closeDialog}
      ></div>
    </dialog>
  );
});
