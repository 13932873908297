import { BrProps } from "@bloomreach/react-sdk";
import { ContainerItem, getContainerItemContent } from "@bloomreach/spa-sdk";
import Configurator from "@pni/pni.configurator.client/component";
import { setConfig } from "@pni/pni.configurator.client/config";
import { ReactElement } from "react";
import { setBrowseDesignsPath } from "./configurator.helpers";
interface ProductConfig {
  configOptions: {
    sourceName: string;
    selectionValues: {
      label: string;
      key: string;
    }[];
  };
}

export const Index = ({
  component,
  page,
}: BrProps<ContainerItem>): ReactElement | null => {
  if (!component || !page) {
    return null;
  }

  const { configOptions } =
    getContainerItemContent<ProductConfig>(component, page) ?? {};

  const productType = configOptions?.selectionValues[0].label;

  setConfig({
    apiUrl: process.env.NEXT_PUBLIC_PRODUCT_API_URL ?? "",
    optionConfigurationApiUrl:
      process.env.NEXT_PUBLIC_OPTION_CONFIGURATION_API_URL ?? "",
    slaApiUrl: process.env.NEXT_PUBLIC_SLA_API_URL ?? "",
    sku: configOptions?.selectionValues[0].key ?? "",
    productType: productType ? setBrowseDesignsPath(productType) : undefined,
  });

  return <Configurator />;
};
