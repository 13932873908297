export const ptoCardsParams = [
  "ptoSku",
  "productOptions",
  "ptoOptions",
  "attributes",
] as const;
export const ptoCardsAttributes = [
  "Size",
  "Sides",
  "CardType",
  "Featured",
] as const;
