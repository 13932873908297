import React, { useEffect, useMemo, useState, useRef } from "react";
import { Reference } from "@bloomreach/spa-sdk";
import { useResize } from "hooks/useResize";
import { linkAttributes } from "services/linkAttributes";
import { Tile, HeaderSection } from "./Utils";
import { ElementsProps, TileDocumentProps } from "./types";
import styles from "./ProductInpiration.module.scss";
import clsx from "clsx";
import { v4 as uuidv4 } from "uuid";

export const ElementsContainer = ({ page, data }: ElementsProps) => {
  const [isMobile, setIsMobile] = useState(false);
  const [activeButtonIndex, setActiveButtonIndex] = useState<number | null>(
    null
  );
  const containerRef = useRef<HTMLDivElement>(null);

  const { title, description, ctaButton, inspirationCards, headerPosition } =
    data;

  const tilesQty = inspirationCards.length;
  const headerPos = headerPosition.selectionValues[0].key;

  const linkProps = useMemo(
    () => linkAttributes(ctaButton.address, ctaButton.newTab),
    [ctaButton.address, ctaButton.newTab]
  );

  const size = useResize();

  useEffect(() => setIsMobile(size < 821), [size]);

  const headerProps = useMemo(
    () => ({
      title,
      description,
      linkProps,
      ctaButton,
      tilesQty,
    }),
    [title, description, linkProps, ctaButton, tilesQty]
  );

  const createTiles = (cards: Reference[], limit = 3) =>
    cards
      .filter((_, index) => index < limit)
      .map((tile, index) => {
        const document = page.getContent(tile);
        if (!document) return null;
        const tileData = document.getData<TileDocumentProps>();
        const tileProps = {
          tileData,
          tilesQty,
          headerPos,
          index,
          isMobile,
          page,
          isActiveButton: activeButtonIndex === index,
          onButtonClick: (e: React.MouseEvent<HTMLButtonElement>) => {
            e.preventDefault();
            setActiveButtonIndex(index === activeButtonIndex ? null : index);
          },
        };
        return <Tile key={index} {...tileProps} />;
      });

  const desktopLayout = () => {
    const tiles = createTiles(inspirationCards);
    const header = <HeaderSection {...headerProps} key={uuidv4()} />;
    const layouts: {
      [key: string]: (JSX.Element | (JSX.Element | null)[] | null)[];
    } = {
      Left: [header, ...tiles],
      Right: [...tiles, header],
      Center:
        tilesQty === 1
          ? [header, ...tiles]
          : [
              ...tiles.slice(0, Math.floor(tilesQty / 2)),
              header,
              ...tiles.slice(Math.floor(tilesQty / 2)),
            ],
    };
    return layouts[headerPos] || [header, ...tiles];
  };

  return (
    <article className={styles.componentWrapper} ref={containerRef}>
      <div
        className={clsx(styles.container, {
          [styles.containerGridTwo]: tilesQty === 2,
          [styles.containerGridThree]: tilesQty >= 3,
        })}
        data-testid="container"
      >
        {isMobile
          ? [
              <HeaderSection {...headerProps} key={uuidv4()} />,
              ...createTiles(inspirationCards),
            ]
          : desktopLayout()}
      </div>
    </article>
  );
};
