import { useCallback, useEffect, useId, useState } from "react";
import styles from "./TemplateSearchUI.module.scss";
import clsx from "clsx";
import { BreadcrumbsProps, VisualFilterItem } from "./types";
import ArrowDouble from "../../public/static-pages/icons/arrow-double.svg";
import Image from "next/image";
import { useSearchParams } from "next/navigation";
import { ptoCardsParams, ptoCardsAttributes } from "./config";

export function TemplateSearchContainer({
  children,
}: {
  children: React.ReactNode;
}) {
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    setMounted(true);
  }, []);

  return <div className={styles.searchUiWrapper}>{mounted && children}</div>;
}

export function SeoMarketingCopy({
  copy,
  moreCopy,
}: {
  copy: string;
  moreCopy?: string;
}) {
  const id = useId();
  return (
    <div className={styles.seoText}>
      <input id={id} type="checkbox" className={styles.seoTextCheckbox} />
      <div dangerouslySetInnerHTML={{ __html: copy }} />
      {moreCopy && (
        <div className={styles.seoTextMore}>
          <div dangerouslySetInnerHTML={{ __html: moreCopy }} />
          <label htmlFor={id} className={styles.seoTextLabel}>
            Less
          </label>
        </div>
      )}
      {moreCopy && (
        <label htmlFor={id} className={styles.seoTextLabel}>
          More
        </label>
      )}
    </div>
  );
}

export function Breadcrumbs({ links }: { links: BreadcrumbsProps[] }) {
  const length = links.length;
  return (
    <nav aria-label="Breadcrumbs" className={styles.breadcrumbs}>
      <ol className={styles.breadcrumbsList}>
        {links.map((link, index) => (
          <li key={index}>
            <a
              href={link.link}
              aria-current={index + 1 === length ? "page" : undefined}
              className={clsx(styles.breadcrumbsLink, {
                [styles.breadcrumbsLinkCurrent]: index + 1 === length,
              })}
            >
              {link.label}
            </a>
            {index + 1 < length && <ArrowDouble />}
          </li>
        ))}
      </ol>
    </nav>
  );
}

export function ptoAttributesBuilder(attributes: string) {
  const allowedAttributes: string[] = [...ptoCardsAttributes];
  const filteredAttributes: string[] = [];
  const splitAttributes = attributes.split("|");

  splitAttributes.forEach((item) => {
    if (allowedAttributes.indexOf(item.split(":", 1)[0]) !== -1)
      filteredAttributes.push(item);
  });

  return filteredAttributes.join("|");
}

export function VisualFilter({ data }: { data?: VisualFilterItem }) {
  const searchParams = useSearchParams();

  const createQueryString = useCallback(() => {
    const params = new URLSearchParams(searchParams?.toString());
    const filteredParams = new URLSearchParams();

    ptoCardsParams.forEach((param) => {
      let value = params.get(param);

      if (param === "attributes" && value) {
        value = ptoAttributesBuilder(value);
      }

      if (value) {
        filteredParams.set(param, value);
      }
    });

    return filteredParams.toString();
  }, [searchParams]);

  if (!data) return null;

  return (
    <a
      className={styles.visualFilter}
      href={data.link + "?" + createQueryString()}
    >
      <Image
        src={data.image as string}
        alt=""
        width={144}
        height={144}
        className={styles.visualFilterImage}
      />
      <span className={styles.visualFilterLabel}>{data.categoryName}</span>
    </a>
  );
}
