"use client";

import { BrProps } from "@bloomreach/react-sdk";
import { ContainerItem, ImageSet } from "@bloomreach/spa-sdk";
import { ReactElement } from "react";
import PtoTemplateSearch from "@pni/pni.template.search.ui";
import { setConfig } from "@pni/pni.template.search.ui";
import { CarouselContainer } from "@/components/utils/NewCarouselContaner";
import {
  TemplateSearchContainer,
  SeoMarketingCopy,
  Breadcrumbs,
  VisualFilter,
} from "./TemplateSearchUI.helpers";
import { Headline } from "../utils/Headline";
import { Document } from "@bloomreach/spa-sdk";
import { Section } from "../utils/Section";
import { extractLabelAndLink } from "services/textHelpers";
import sanitizeHtml from "sanitize-html";
import { VisualFiltersProps } from "./types";
import { Suspense } from "react";

export const Index = ({
  page,
}: BrProps<ContainerItem>): ReactElement | null => {
  const document = page?.getDocument<Document>();

  setConfig({
    apiUrl: process.env.NEXT_PUBLIC_PRODUCT_API_URL ?? "",
    optionConfigurationApiUrl:
      process.env.NEXT_PUBLIC_OPTION_CONFIGURATION_API_URL ?? "",
    slaApiUrl: process.env.NEXT_PUBLIC_SLA_API_URL ?? "",
    sku: "",
    templateApi: process.env.NEXT_PUBLIC_TEMPLATE_API ?? "",
    createProjectUrl: "/services/printing/builder/group/CreateProjectGroup",
    googleAnalyticsKey: process.env.NEXT_PUBLIC_GA_KEY ?? "",
    harmonyLibPath: process.env.NEXT_PUBLIC_HARMONY_LIB_PATH ?? "",
    textureAssetPath: process.env.NEXT_PUBLIC_TEXTURE_ASSET_PATH ?? "",
    render3dBaseUrl: process.env.NEXT_PUBLIC_RENDER_3D_BASE_URL ?? "",
  });

  if (document?.getData().contentType !== "brxsaas:pmSeoTemplateSelection") {
    return page?.isPreview() ? (
      <div>
        Please use <strong>PM - SEO Template Selection</strong> doucment for
        Template Selection pages{" "}
      </div>
    ) : null;
  }

  const { ...props } = document.getData<SeoTemplateSearch>();
  const visualFilters =
    props.visualFilters &&
    page
      ?.getContent<Document>(props.visualFilters)
      ?.getData<VisualFiltersProps>();
  const visualFilterItems = visualFilters?.pmVisualFilter.map((item) => {
    const imageUrl = page
      ?.getContent<ImageSet>(item.image)
      ?.getOriginal()
      ?.getUrl();
    return {
      ...item,
      image: imageUrl,
    };
  });

  const copy =
    props.seoMarketingCopy.length > 0 &&
    page?.rewriteLinks(sanitizeHtml(props.seoMarketingCopy[0].value));
  const moreCopy =
    props.seoMarketingCopy.length > 1 &&
    page?.rewriteLinks(sanitizeHtml(props.seoMarketingCopy[1].value));

  const breadcrumbsLinks = props.breadcrumbs.map((crumb) =>
    extractLabelAndLink(crumb)
  );

  return (
    <Section ariaLabel={props.heading}>
      {breadcrumbsLinks.length > 1 && <Breadcrumbs links={breadcrumbsLinks} />}
      <Headline level="h1" style={["h3"]}>
        {props.heading}
      </Headline>
      {visualFilterItems && (
        <Suspense>
          <CarouselContainer list={visualFilterItems} simpleScroll>
            <VisualFilter />
          </CarouselContainer>
        </Suspense>
      )}
      <TemplateSearchContainer>
        <PtoTemplateSearch />
      </TemplateSearchContainer>
      {copy && (
        <SeoMarketingCopy
          copy={copy}
          moreCopy={typeof moreCopy === "string" ? moreCopy : undefined}
        />
      )}
    </Section>
  );
};
